import { call, put } from 'redux-saga/effects';

import UserClient from '../../api/UserClient';
import { userResetPasswordSuccessful, userResetPasswordFailed } from '../../redux/user/actions';
import { logoutRequest } from '../../redux/login/actions';
import { messagesShow } from '../../redux/messages/actions';

export default function*({ payload: { token, password } }) {
    try {
        yield call(UserClient.resetLostPassword, token, password);
        yield put(userResetPasswordSuccessful());
        yield put(messagesShow('resetPasswordSuccefull', 'success'));
    } catch (data) {
        const { code } = data;
        if (code === 400) {
            const errorObject = JSON.parse(data.message.message);
            errorObject.messageCode = errorObject.message_code;
            delete errorObject.message_code;
            yield put(userResetPasswordFailed());
            if (errorObject && errorObject.error && errorObject.messageCode) {
                if (errorObject.messageCode === 'BadPasswordWeak') {
                    yield put(messagesShow('errorPasswordTooWeak', 'error'));
                } else if (errorObject.messageCode === 'ReusingPassword') {
                    yield put(messagesShow('errorReusingPassword', 'error'));
                }
            } else {
                yield put(messagesShow('internalServerError', 'error'));
            }
        } else if (code === 401) {
            yield put(logoutRequest());
        } else if (code === 424) {
            yield put(userResetPasswordFailed());
            yield put(messagesShow('loginNotExist', 'error'));
        } else {
            yield put(userResetPasswordFailed());
            yield put(messagesShow('internalServerError', 'error'));
        }
    }
}
